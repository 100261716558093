.media-body {
    flex: 1 1;
    text-align: left;
}

.buttonNav {
    background:none!important;
     border:none; 
     padding:0!important;

     color:#069;
     cursor:pointer;
}
.buttonNav:hover{
         text-decoration:underline;
}

@media (min-width: 992px)
{
    .form-group-users {
        min-width: 350px;
    }
}