.social-icons li {
margin-right: 10px;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
}

a :hover {
    color: #0f9246;
    text-decoration: none;
    filter: brightness(100%);
}

a {
    color:#fff;

}